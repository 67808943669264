import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { OrderDetails } from "../types/OrderDetailsType";
import { generateAuthToken, generateRegistrationCode } from "./codeGenerator";
import * as persistenceManager from "./persistenceManager";
import * as errorFactory from "./errorFactory";
import { Region } from "../types/RegionType";
import { wait, getScreenId } from "./utils";
import { StateDefinitionConfig } from "../types/StateDefinitionType";
import { Store } from "../types/StoreType";
import * as errorMessages from "../constants/errorMessage";

// Overrides all URLs if REACT_APP_OVERRIDE_API_URL is set
// When hot loading during development this is set in package.json
const OVERRIDE_API_URL = process.env.REACT_APP_OVERRIDE_API_URL;
const OVERRIDE_AU_API_URL = process.env.REACT_APP_OVERRIDE_AU_API_URL;
const OVERRIDE_EU_API_URL = process.env.REACT_APP_OVERRIDE_EU_API_URL;
const OVERRIDE_US_API_URL = process.env.REACT_APP_OVERRIDE_US_API_URL;

const NETWORK_REQUEST_TIMEOUT = 10000;

const screenApiUrls = {
  au: OVERRIDE_API_URL || OVERRIDE_AU_API_URL || "https://au1-screens.bluedot.io",
  eu: OVERRIDE_API_URL || OVERRIDE_EU_API_URL || "https://eu1-screens.bluedot.io",
  us: OVERRIDE_API_URL || OVERRIDE_US_API_URL || "https://us1-screens.bluedot.io",
};

const generateAuthConfig = (authToken: string): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
};

const generateMetricsAndAuthHeaders = (
  authToken: string,
  retryCount: number,
  eventTime: string,
  previousResponseTime: number | undefined,
  lastUpdateTime: string | undefined
) => {
  const submissionTime = new Date().toISOString();
  const screenId = getScreenId(authToken);
  const options: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "x-bluedot-event-time": eventTime,
      "x-bluedot-submission-time": submissionTime,
      "x-bluedot-retry-count": retryCount,
      "x-bluedot-screen-id": screenId,
    },
  };
  if (previousResponseTime) {
    options.headers!["x-bluedot-previous-response-time"] = previousResponseTime;
  }
  if (lastUpdateTime) {
    options.headers!["x-bluedot-last-update-time"] = lastUpdateTime;
  }
  return options;
};

export const getWebsocketUrl = (region: Region) => {
  return `${screenApiUrls[region].replace("https", "wss")}/realtime`;
};

interface GetOrdersResponse {
  orderDetails: OrderDetails[];
  stateHash?: string;
}

let getOrdersRetries = persistenceManager.getGetOrderRetries();
let getOrdersPreviousResponseTime: undefined | number;
export const getOrders = async (
  authToken: string,
  region: Region,
  status?: string
): Promise<GetOrdersResponse> => {
  if (!region) {
    throw errorFactory.createNoRegionError();
  }
  let url = `${screenApiUrls[region]}/orders`;
  if (status) {
    url = url.concat(`?status=${status}`);
  }

  if (!authToken) {
    throw errorFactory.createNoTokenError();
  }

  const eventTime = new Date();
  // We send information on the amount of retries and response time in the headers so that we are able
  // to identify if users are experiencing connection issues. This is because initially we had a lot of complaints
  // due to customer having poor wifi coverage.
  const options = generateMetricsAndAuthHeaders(
    authToken,
    getOrdersRetries,
    eventTime.toISOString(),
    getOrdersPreviousResponseTime,
    undefined
  );
  const abortController = new AbortController();
  try {
    const getOrdersResponsePromise = axios.get(url, {
      ...options,
      signal: abortController.signal,
    });
    // We added these timeouts and providing error messages for the same reason as above, users were having issue due to poor network
    // performance but blaming the UI, so now we try and provide feedback when requests are slow and cancel requests that are taking a long time
    setTimeout(() => {
      if (!abortController.signal.aborted) {
        console.log("aborting");
        abortController.abort();
      }
    }, NETWORK_REQUEST_TIMEOUT);
    const getOrdersResponse = await getOrdersResponsePromise;
    const responseTimestamp = new Date();
    abortController.abort();
    getOrdersPreviousResponseTime =
      responseTimestamp.getTime() - eventTime.getTime();
    getOrdersRetries = 0;
    if (getOrdersResponse.status === 200) {
      const stateHash =
        getOrdersResponse.headers["x-bluedot-state-configuration"];
      return {
        orderDetails: getOrdersResponse.data as OrderDetails[],
        stateHash,
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 403) {
        throw errorFactory.createNotYetLinkedError();
      }
    }
    try {
      errorFactory.captureNetworkErrors(error);
    } catch (err) {
      getOrdersRetries += 1;
      persistenceManager.setGetOrdersRetries(getOrdersRetries);
      throw err;
    }
    throw errorFactory.createUnknownError(error);
  }

  throw errorFactory.createUnknownError();
};

interface GetWaitTimeResponse {
  waitTimeAverage: number;
  numberOfOrders: number;
  unit?: string; // This will be removed after deployment of wait time goals project
}

export const getWaitTime = async (
  authToken: string,
  region: Region,
  timezoneOffset: number
): Promise<GetWaitTimeResponse> => {
  if (!region) {
    throw errorFactory.createNoRegionError();
  }

  let url = `${screenApiUrls[region]}/orders/waitTime`;
  if (timezoneOffset) {
    url = url.concat(`?timezoneOffset=${timezoneOffset}`);
  }

  if (!authToken) {
    throw errorFactory.createNoTokenError();
  }

  const authConfig = generateAuthConfig(authToken);

  try {
    const getWaitTimeResponse = await axios.get(url, authConfig);
    if (getWaitTimeResponse.status === 200) {
      return getWaitTimeResponse.data as GetWaitTimeResponse;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 403) {
        throw errorFactory.createNotYetLinkedError();
      }
    }
    throw errorFactory.createUnknownError(error);
  }

  throw errorFactory.createUnknownError();
};

const registerNewToken = async (
  authToken: string,
  registrationCode: string,
  region: Region
) => {
  const url = `${screenApiUrls[region]}/credentials/register`;
  const payload = {
    code: registrationCode,
    token: authToken,
  };

  try {
    const registrationResponse = await axios.post(url, payload);
    if (registrationResponse.status === 204) {
      return true;
    }
    console.log(
      `Registration of code ${registrationCode} with token ${authToken} failed`,
      registrationResponse
    );
    return false;
  } catch {
    console.log("Failed to register using payload", payload);
    return false;
  }
};

export const registerViaUrlCode = async (urlCode: string, region: Region) => {
  const url = `${screenApiUrls[region]}/credentials/url/linkCode`;
  const authToken = generateAuthToken();
  const payload = {
    code: urlCode,
    token: authToken,
  };
  try {
    const result = await axios.post(url, payload);
    if (result.status === 200) {
      persistenceManager.storeAuthToken(authToken);
      persistenceManager.setRegion(region);
      persistenceManager.storeRegistrationCode(urlCode);
    }
    return true;
  } catch (error) {
    console.log("Failed to register using payload", payload);
    throw error;
  }
};

/**
 * Randomly generates a registration code and auth token and attempts to register it with Screen Api
 * If registration is successful then the values will be saved to local storage
 * @returns Returns a successfully registered registration code
 * @throws An error if registration of a new code failed after 4 retries
 */
export const registerNewCode = async (region: Region) => {
  const maxRegistrationAttempts = 4;
  let currentRegistrationAttempts = 0;
  let registrationSuccessful = false;
  let newAuthToken;
  let newRegistrationCode;

  if (!region) {
    throw errorFactory.createNoRegionError();
  }

  // This loop is to handle the case where the randomly generated values are already in use, very unlikely to happen even once
  while (
    !registrationSuccessful &&
    currentRegistrationAttempts < maxRegistrationAttempts
  ) {
    currentRegistrationAttempts = currentRegistrationAttempts + 1;
    newAuthToken = generateAuthToken();
    newRegistrationCode = generateRegistrationCode();

    registrationSuccessful = await registerNewToken(
      newAuthToken,
      newRegistrationCode,
      region
    );
  }

  if (registrationSuccessful && newAuthToken && newRegistrationCode) {
    persistenceManager.storeAuthToken(newAuthToken);
    persistenceManager.storeRegistrationCode(newRegistrationCode);
    console.log(`Stored code: ${newRegistrationCode}, token: ${newAuthToken}`);
    return newRegistrationCode;
  }

  console.log(
    `An unexpected error occurred while trying to register your screen. Attempted ${currentRegistrationAttempts} times.`
  );
  throw errorFactory.createRegistrationFailedError();
};

let updateOrderStatusRetries = persistenceManager.getUpdateOrderStatusRetries();
let updateOrderStatusPreviousResponseTime: undefined | number;
export const updateOrderStatus = async (
  orderId: string,
  newStatus: string,
  authToken: string,
  region: Region,
  eventTime: string,
  lastUpdateTime: string,
  abortController: AbortController | undefined,
  verificationPatch?: VerificationPatch
) => {
  if (!region) {
    throw errorFactory.createNoRegionError();
  }

  let url = `${screenApiUrls[region]}/orders/${encodeURIComponent(orderId)}`;
  const payload = [
    {
      op: "replace",
      path: "/status",
      value: newStatus,
    },
  ];
  if (verificationPatch) {
    payload.push(verificationPatch);
  }

  const authConfig = generateMetricsAndAuthHeaders(
    authToken,
    updateOrderStatusRetries,
    eventTime,
    updateOrderStatusPreviousResponseTime,
    lastUpdateTime
  );
  if (abortController) {
    authConfig.signal = abortController.signal;
  }

  try {
    const updateOrderResponse = await axios.patch(url, payload, authConfig);
    abortController?.abort();
    return updateOrderResponse.data;
  } catch (error) {
    errorFactory.captureNetworkErrors(error);
    errorFactory.captureInvalidVerificationCodeError(error);
    throw errorFactory.createUnknownError(error);
  }
};

export interface VerificationPatch {
  op: "replace";
  path: "/verificationCode" | "/verificationErrorCode";
  value: string;
}

export const updateOrderStatusWithRetries = async (
  orderId: string,
  newStatus: string,
  authToken: string,
  region: Region,
  setErrorMessage: (errorMessage: string) => void,
  eventTime: string,
  lastUpdateTime: string,
  verificationPatch?: VerificationPatch
) => {
  const MAX_RETRIES = 3;
  const WAIT_TIME = 100;
  let retries = 0;
  let lastError;
  setErrorMessage("");
  while (retries < MAX_RETRIES) {
    let abortController = new AbortController();
    try {
      abortController = new AbortController();
      const responsePromise = updateOrderStatus(
        orderId,
        newStatus,
        authToken,
        region,
        eventTime,
        lastUpdateTime,
        abortController,
        verificationPatch
      );
      if (retries === 0) {
        setTimeout(() => {
          if (!abortController.signal.aborted) {
            setErrorMessage(errorMessages.SLOW_NETWORK);
          }
        }, NETWORK_REQUEST_TIMEOUT / 2);
      }
      setTimeout(() => {
        abortController.abort();
      }, NETWORK_REQUEST_TIMEOUT);
      await responsePromise;
      updateOrderStatusRetries = 0;
      persistenceManager.setUpdateOrderStatusRetries(updateOrderStatusRetries);
      return;
    } catch (error) {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
      lastError = error;
      console.log("Error updating order", error);
      retries += 1;
      if (
        error instanceof Error &&
        error?.name === errorFactory.NETWORK_CONNECTION_ERROR
      ) {
        updateOrderStatusRetries += 1;
        persistenceManager.setUpdateOrderStatusRetries(
          updateOrderStatusRetries
        );
        if (retries === MAX_RETRIES) {
          setErrorMessage(errorMessages.NETWORK_FAILED(retries));
        } else {
          setErrorMessage(errorMessages.NETWORK_RETRY(retries + 1));
        }
      } else if (
        error instanceof Error &&
        error?.name === errorFactory.INVALID_VERIFICATION_CODE_ERROR
      ) {
        setErrorMessage(error.message);
        throw error;
      } else {
        setErrorMessage(errorMessages.UNEXPECTED_ERROR_OCCURRED);
        throw errorFactory.createUnknownError();
      }
      if (retries < MAX_RETRIES) {
        await wait(WAIT_TIME * retries * retries);
      }
    }
  }
  if (retries === MAX_RETRIES) {
    console.log(`Failed to update order status: ${orderId}`);
    throw lastError;
  }
};

let acknowledgeOrdersRetries = persistenceManager.getAcknowledgeOrderRetries();
let acknowledgeOrdersPreviousResponseTime: number | undefined;
export const acknowledgeOrder = async (
  orderId: string,
  authToken: string,
  region: Region,
  eventTime: string,
  lastUpdateTime: string,
  abortController: AbortController | undefined
) => {
  if (!region) {
    throw errorFactory.createNoRegionError();
  }

  let url = `${screenApiUrls[region]}/orders/${encodeURIComponent(orderId)}`;
  const payload = [
    {
      op: "replace",
      path: "/isAcknowledged",
      value: true,
    },
  ];

  const authConfig = generateMetricsAndAuthHeaders(
    authToken,
    acknowledgeOrdersRetries,
    eventTime,
    acknowledgeOrdersPreviousResponseTime,
    lastUpdateTime
  );
  try {
    const acknowledgeOrderResponse = await axios.patch(url, payload, {
      ...authConfig,
      signal: abortController?.signal,
    });
    abortController?.abort();
    return acknowledgeOrderResponse.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 400) {
        throw errorFactory.createInvalidAcknowledgementError();
      }
    }
    errorFactory.captureNetworkErrors(error);
    throw errorFactory.createUnknownError(error);
  }
};

export const acknowledgeOrderWithRetries = async (
  orderId: string,
  authToken: string,
  region: Region,
  eventTime: string,
  lastUpdateTime: string,
  setErrorMessage: ((message: string) => void) | undefined
) => {
  const MAX_RETRIES = 3;
  const WAIT_TIME = 100;
  let retries = 0;
  let lastError;
  if (setErrorMessage) {
    setErrorMessage("");
  }
  while (retries < MAX_RETRIES) {
    let abortController: AbortController = new AbortController();
    try {
      const sendRequestTimestamp = new Date();
      abortController = new AbortController();
      const acknowledgeOrderRequest = acknowledgeOrder(
        orderId,
        authToken,
        region as Region,
        eventTime,
        lastUpdateTime,
        abortController
      );
      if (retries === 0) {
        setTimeout(() => {
          if (!abortController.signal.aborted && setErrorMessage) {
            setErrorMessage(errorMessages.SLOW_NETWORK);
          }
        }, NETWORK_REQUEST_TIMEOUT / 2);
      }
      setTimeout(() => {
        abortController.abort();
      }, NETWORK_REQUEST_TIMEOUT);
      await acknowledgeOrderRequest;
      const receiveRequestTimestamp = new Date();
      acknowledgeOrdersPreviousResponseTime =
        receiveRequestTimestamp.getTime() - sendRequestTimestamp.getTime();
      acknowledgeOrdersRetries = 0;
      persistenceManager.setAcknowledgeOrdersRetries(acknowledgeOrdersRetries);
      return;
    } catch (error) {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
      lastError = error;
      console.log("Error acknowledging order", error);
      if (
        error instanceof Error &&
        error?.name === errorFactory.INVALID_ACKNOWLEDGEMENT_ERROR
      ) {
        return;
      }
      retries += 1;
      if (
        error instanceof Error &&
        error?.name === errorFactory.NETWORK_CONNECTION_ERROR
      ) {
        acknowledgeOrdersRetries += 1;
        persistenceManager.setAcknowledgeOrdersRetries(
          acknowledgeOrdersRetries
        );
        if (setErrorMessage) {
          if (retries === MAX_RETRIES) {
            setErrorMessage(errorMessages.NETWORK_FAILED(retries));
          } else {
            setErrorMessage(errorMessages.NETWORK_RETRY(retries + 1));
          }
        }
      } else if (setErrorMessage) {
        setErrorMessage(errorMessages.UNEXPECTED_ERROR_OCCURRED);
      }
      if (retries < MAX_RETRIES) {
        await wait(WAIT_TIME * retries * retries);
      }
    }
  }
  if (retries === MAX_RETRIES) {
    console.log(`Failed to acknowledge order: ${orderId}`);
    throw lastError;
  }
};

export interface StateDefinitionsResponse {
  stateDefinitions: StateDefinitionConfig;
  stateHash?: string;
}

export const getStateDefinitions = async (
  authToken: string,
  region: Region
): Promise<StateDefinitionsResponse> => {
  if (!region) {
    throw errorFactory.createNoRegionError();
  }
  const url = `${screenApiUrls[region]}/configuration/states`;
  const authConfig = generateAuthConfig(authToken);

  try {
    const getStateDefinitionsResponse = await axios.get(url, authConfig);
    const stateDefinitionsHeader =
      getStateDefinitionsResponse.headers["x-bluedot-state-configuration"];
    return {
      stateDefinitions: getStateDefinitionsResponse.data,
      stateHash: stateDefinitionsHeader,
    };
  } catch (error) {
    throw errorFactory.createUnknownError(error);
  }
};

export const getStore = async (
  authToken: string,
  region: Region
): Promise<Store> => {
  if (!region) {
    throw errorFactory.createNoRegionError();
  }

  const url = `${screenApiUrls[region]}/me`;
  const authConfig = generateAuthConfig(authToken);
  try {
    const getStoreResponse = await axios.get(url, authConfig);
    return getStoreResponse.data;
  } catch (error) {
    throw errorFactory.createUnknownError(error);
  }
};

interface SsoUrlCodeDetails {
  projectId: string;
  identityProvider: string;
}

export const getSsoUrlCodeDetails = async (
  urlCode: string,
  region: Region
): Promise<SsoUrlCodeDetails> => {
  if (!region) {
    throw errorFactory.createNoRegionError();
  }

  const url = `${screenApiUrls[region]}/credentials/sso/getUrlCode/${urlCode}`;

  try {
    const getUrlCodeDetailsResponse = await axios.get(url);
    return getUrlCodeDetailsResponse.data;
  } catch (error) {
    throw errorFactory.createUnknownError(error);
  }
};

export const getAuthTokenViaSso = async (
  region: Region,
  ssoIdToken: string,
  destinationId: string,
  projectId: string
) => {
  const url = `${screenApiUrls[region]}/credentials/sso/register`;
  const config = {
    headers: { Authorization: `Bearer ${ssoIdToken}` },
  };
  const body = {
    projectId,
    destinationId,
  };

  const result = await axios.post(url, body, config);
  return result.data.token;
};
